@import "../node_modules/bootstrap/scss/variables";

[data-bs-theme="blue"] {
    --bs-theme-100: #CFE2FF;
    --bs-theme-200: #9EC5FE;
    --bs-theme-300: #6EA8FE;
    --bs-theme-400: #3D8BFD;
    --bs-theme-500: #0D6EFD;
    --bs-theme-500-rgb: 13, 110, 253 !important;
    --bs-theme-600: #0A58CA;
    --bs-theme-600-rgb: 10, 88, 202 !important;
    --bs-theme-700: #084298;
    --bs-theme-800: #052C65;
    --bs-theme-900: #031633;
}

[data-bs-theme="indigo"] {
    --bs-theme-100: #E0CFFC;
    --bs-theme-200: #C29FFA;
    --bs-theme-300: #A370F7;
    --bs-theme-400: #8540F5;
    --bs-theme-500: #6610F2;
    --bs-theme-500-rgb: 102, 16, 242 !important;
    --bs-theme-600: #520DC2;
    --bs-theme-600-rgb: 82, 13, 194 !important;
    --bs-theme-700: #3D0A91;
    --bs-theme-800: #290661;
    --bs-theme-900: #140330;
}

[data-bs-theme="purple"] {
    --bs-theme-100: #E2D9F3;
    --bs-theme-200: #C5B3E6;
    --bs-theme-300: #A98EDA;
    --bs-theme-400: #8C68CD;
    --bs-theme-500: #6F42C1;
    --bs-theme-500-rgb: 111, 66, 193 !important;
    --bs-theme-600: #59359A;
    --bs-theme-600-rgb: 89, 53, 154 !important;
    --bs-theme-700: #432874;
    --bs-theme-800: #2C1A4D;
    --bs-theme-900: #160D27;
}

[data-bs-theme="pink"] {
    --bs-theme-100: #F7D6E6;
    --bs-theme-200: #EFADCE;
    --bs-theme-300: #E685B5;
    --bs-theme-400: #DE5C9D;
    --bs-theme-500: #D63384;
    --bs-theme-500-rgb: 214, 51, 132 !important;
    --bs-theme-600: #AB296A;
    --bs-theme-600-rgb: 171, 41, 106 !important;
    --bs-theme-700: #801F4F;
    --bs-theme-800: #561435;
    --bs-theme-900: #2B0A1A;
}

[data-bs-theme="red"] {
    --bs-theme-100: #F8D7DA;
    --bs-theme-200: #F1AEB5;
    --bs-theme-300: #EA868F;
    --bs-theme-400: #E35D6A;
    --bs-theme-500: #DC3545;
    --bs-theme-500-rgb: 220, 53, 69 !important;
    --bs-theme-600: #B02A37;
    --bs-theme-600-rgb: 176, 42, 55 !important;
    --bs-theme-700: #842029;
    --bs-theme-800: #58151C;
    --bs-theme-900: #2C0B0E;
}

[data-bs-theme="orange"] {
    --bs-theme-100: #FFE5D0;
    --bs-theme-200: #FECBA1 !important;
    --bs-theme-300: #FEB272 !important;
    --bs-theme-400: #FD9843 !important;
    --bs-theme-500: #FD7E14;
    --bs-theme-500-rgb: 253, 126, 20 !important;
    --bs-theme-600: #CA6510 !important;
    --bs-theme-600-rgb: 202, 101, 16 !important;
    --bs-theme-700: #984C0C !important;
    --bs-theme-800: #653208 !important;
    --bs-theme-900: #331904 !important;
}

[data-bs-theme="yellow"] {
    --bs-theme-100: #FFF3CD;
    --bs-theme-200: #FFE69C;
    --bs-theme-300: #FFDA6A;
    --bs-theme-400: #FFCD39;
    --bs-theme-500: #FFC107;
    --bs-theme-500-rgb: 255, 193, 7 !important;
    --bs-theme-600: #CC9A06;
    --bs-theme-600-rgb: 204, 154, 6 !important;
    --bs-theme-700: #997404;
    --bs-theme-800: #664D03;
    --bs-theme-900: #332701;
}

[data-bs-theme="green"] {
    --bs-theme-100: #D1E7DD;
    --bs-theme-200: #A3CFBB;
    --bs-theme-300: #75B798;
    --bs-theme-400: #479F76;
    --bs-theme-500: #198754;
    --bs-theme-500-rgb: 25, 135, 84 !important;
    --bs-theme-600: #146C43;
    --bs-theme-600-rgb: 20, 108, 67 !important;
    --bs-theme-700: #0F5132;
    --bs-theme-800: #0A3622;
    --bs-theme-900: #051B11;
}

[data-bs-theme="teal"] {
    --bs-theme-100: #D2F4EA;
    --bs-theme-200: #A6E9D5;
    --bs-theme-300: #79DFC1;
    --bs-theme-400: #4DD4AC;
    --bs-theme-500: #20C997;
    --bs-theme-500-rgb: 32, 201, 151 !important;
    --bs-theme-600: #1AA179;
    --bs-theme-600-rgb: 26, 161, 121 !important;
    --bs-theme-700: #13795B;
    --bs-theme-800: #0D503C;
    --bs-theme-900: #06281E;
}

[data-bs-theme="cyan"] {
    --bs-theme-100: #CFF4FC;
    --bs-theme-200: #9EEAF9;
    --bs-theme-300: #6EDFF6;
    --bs-theme-400: #3DD5F3;
    --bs-theme-500: #0dcaf0;
    --bs-theme-500-rgb: 13, 202, 240 !important;
    --bs-theme-600: #0AA2C0;
    --bs-theme-600-rgb: 10, 162, 192 !important;
    --bs-theme-700: #087990;
    --bs-theme-800: #055160;
    --bs-theme-900: #032830;
}

[data-bs-theme="gray"] {
    --bs-theme-100: #F8F9FA;
    --bs-theme-200: #E9ECEF;
    --bs-theme-300: #DEE2E6;
    --bs-theme-400: #CED4DA;
    --bs-theme-500: #adb5bd;
    --bs-theme-500-rgb: 173, 181, 189 !important;
    --bs-theme-600: #6C757D;
    --bs-theme-600-rgb: 108, 117, 125 !important;
    --bs-theme-700: #495057;
    --bs-theme-800: #343A40;
    --bs-theme-900: #212529;
}

[data-bs-theme] {
    --bs-primary-text-emphasis: var(--bs-theme-800) !important;
    --bs-primary-bg-subtle: var(--bs-theme-100) !important;
    --bs-primary-border-subtle: var(--bs-theme-200) !important;
    --bs-link-color: var(--bs-theme-500) !important;
    --bs-link-color-rgb: var(--bs-theme-500-rgb) !important;
    --bs-link-hover-color: var(--bs-theme-600) !important;
    --bs-link-hover-color-rgb: var(--bs-theme-600-rgb) !important;
    --bs-code-color: var(--bs-theme-500) !important;
    --bs-focus-ring-color: rgba(var(--bs-theme-500-rgb), 0.25) !important;
    --bs-primary-rgb: var(--bs-theme-500-rgb);
    --bs-btn-hover-color: #FFF !important;
    //--bs-border-color: #6C757D;
}

.tooltip {
    //--bs-tooltip-bg: #eef0f3 !important;
    //--bs-tooltip-color: #152536 !important;
    --bs-tooltip-max-width: 500px;
}

.tooltip-inner {
    text-align: left;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-theme-500);
    --bs-btn-border-color: var(--bs-theme-500);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-theme-600);
    --bs-btn-hover-border-color: var(--bs-theme-600);
    --bs-btn-focus-shadow-rgb: 220, 82, 150;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-theme-600);
    --bs-btn-active-border-color: var(--bs-theme-600);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-theme-500);
    --bs-btn-disabled-border-color: var(--bs-theme-500);
}

.btn-outline-primary {
    --bs-btn-color: var(--bs-theme-500);
    --bs-btn-border-color: var(--bs-theme-500);
    --bs-btn-hover-bg: var(--bs-theme-500);
    --bs-btn-hover-border-color: var(--bs-theme-500);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-theme-500);
    --bs-btn-active-border-color: var(--bs-theme-500);
    --bs-btn-disabled-color: var(--bs-theme-500);
    --bs-btn-disabled-border-color: var(--bs-theme-500);
    --bs-btn-hover-color: #fff;
}

.form-check-input:checked {
    background-color: var(--bs-theme-500);
    border-color: var(--bs-theme-500);
}

.form-control:focus {
    border-color: var(--bs-theme-200);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-theme-500-rgb), 0.25);
}

.form-select:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-theme-500-rgb), 0.25);
}

.form-control {
    border: 1px solid #6C757D !important;
}

.dropdown-menu {
    --bs-dropdown-link-active-color: var(--bs-theme-600);
    --bs-dropdown-link-active-bg: var(--bs-theme-100);
}

.navbar-nav {
    --bs-nav-link-color: #152536; //body-color
}

.navbar-nav .nav-link.active {
    color: var(--bs-theme-500) !important;
}
