html {
    font-size: 16px;
    width: 100vw;
    margin: 0;
}

body {
    margin: 0;
    overflow-x: hidden;
    height: 100vh;
    font-family: var(--font-inter);
    width: 100%;
}

.Toastify {
    position: absolute;
}

a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
}

textarea,
input {
    /*font-family: var(--font-inter);*/
}

* {
    box-sizing: border-box;
}

input[type="search"]::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;

    height: 0;
    width: 0;
}

/* Partial css reset */

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

ul, ol {
    //list-style-type: none;
    margin: 0;
    padding: 0;
    border: 0;
}

$primary: #56d633;
$font-weight-bolder: 800;
$body-color: #152536;
$input-placeholder-color: #68717A;
$font-family-base: var(--font-inter);
$form-check-input-width: 16px;
$form-check-padding-start: 24px;
$modal-lg: 600px;
@import "~bootstrap/scss/bootstrap";

@import "themes";

.text-sm {
    font-size: 0.875rem !important;
}

.text-xs {
    font-size: 0.75rem !important;
}

.text-primary-dark {
    color: #5227CC;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}


.form-check {
    cursor: pointer;

    & > * {
        cursor: pointer;
    }
}

.form-check-input {
    margin-top: 0;
}

.form-label {
    margin-bottom: 4px !important;
    height: 15px;
}

.form-control.is-invalid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
}

textarea.form-control.is-invalid {
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
}

.form-select.form-select-no-borders {
    cursor: pointer;
    border: none;

    background-color: transparent;
    background-image: url('/chevron-down.svg');
    padding-top: 0;
    padding-bottom: 0;
}

.form-select option:checked, .form-select option:hover, .form-select option:focus, .form-select option:active {
    background-color: var(--bs-theme-500) !important;
    color: #FFF !important;
}

.dropdown-toggle.nav-link {
    display: flex;
    align-items: center;
}

.dropdown-nav-pill {
    .dropdown-toggle.nav-link {
        border-radius: 4px;
        background: var(--bs-theme-500);
        font-weight: 600;
        color: #FFF;
    }

    .dropdown-toggle.nav-link::after {
        background-image: url('/chevron-down-white.svg');
    }
}


.dropdown-toggle.nav-link::after {
    width: 16px;
    height: 16px;
    vertical-align: unset;
    border: none !important;
    background-image: url('/chevron-down-grey.svg');
}

.input-with-percentage {
    background-image: url('/chevron-down-grey.svg');
    background-repeat: no-repeat;
    background-position: right calc(1.5rem) center;
    padding-right: 1.5rem;
}

.modal-header {
    background: var(--bs-theme-100);
}

.modal-title {
    color: var(--heading-title-color, #152536);
    font-size: 1rem;
    font-weight: 600;
}

.modal-footer {
    border: none;
}

textarea.form-control {
    resize: none;
    min-height: 130px;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: #DEE2E6;
    margin: 12px 0;
}

.container-fluid {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

.block-max-width {
    max-width: 1440px;
    margin: 0 auto;
}

.btn-dark {
    --bs-btn-bg: #343A40;
    --bs-btn-border-color: #343A40;
    --bs-btn-disabled-bg: #343A40;
    --bs-btn-disabled-border-color: #343A40;
}

.btn-light {
    --bs-btn-color: #6C757D;
    --bs-btn-hover-color: #6C757D;
    --bs-btn-active-color: #6C757D;
    --bs-btn-disabled-color: #6C757D;
}

.pagination {
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem var(--bs-theme-500) !important;
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: var(--bs-theme-500) !important;
    --bs-pagination-active-border-color: var(--bs-theme-500) !important;
}
