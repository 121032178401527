.spinnerOverlay {
    z-index: 99999;
    position: fixed;
    inset: 0;

    .content {
        -webkit-overflow-scrolling: touch;
        position: absolute;
        background-color: #000;
        width: 100%;
        height: 100%;
        opacity: 0.2;

        &.notTransparent {
            background-color: #FFF;
            opacity: 1;
        }
    }

    .loader {
        position: absolute;
        top: 50%;
        right: 50%;
        z-index: 10;
    }
}
